// Let us open our database
// console.log("Track User File Loaded");
const dbName = 'testDB';
const DB = new Promise((resolve, reject) => {
    const request = window.indexedDB.open(dbName, 1);
    request.onupgradeneeded = () => {
        request.result.createObjectStore('trackUserRouting', {
            autoIncrement: true,
        });
    };
    request.onsuccess = () => resolve(request.result);
    request.onerror = (e) => reject(e);
});

const migrate = (data) => {
    DB.then((db) => {
        const store = db
            .transaction('trackUserRouting', 'readwrite')
            .objectStore('trackUserRouting');
        let insertData = {
            routeUrl: data.routeUrl,
            userId: data.userId,
            token: data.token,
            date: new Date().toISOString(),
        };

        store.add(insertData);
    });
};

let userRoutingTrack;
const getList = () => {
    DB.then((db) => {
        const request = db
            .transaction('trackUserRouting')
            .objectStore('trackUserRouting')
            .getAll();
        request.onsuccess = (e) => {
            userRoutingTrack = e.target.result;
        };
    });
};

const clearDB = () => {
    DB.then((db) => {
        const objectStore = db
            .transaction('trackUserRouting', 'readwrite')
            .objectStore('trackUserRouting');
        const request = objectStore.clear();
        request.onsuccess = (e) => {
            // console.log(e.target.result);
            userRoutingTrack = [];
        };
    });
};
function clearIndexedDB() {
    clearDB();
    isUserLoggedOut();
}

userRoutingTrack = [];

function trackUserRouting(data) {
    userRoutingData(data);
}

function userRoutingData(data) {
    migrate(data);
    getList();
}

let bothData = {};
const errorData = [];
const warnData = [];
const errorObj = {
    type: 'error',
    data: [],
};
const warnObj = {
    type: 'warn',
    data: [],
};

function getCrashesFromConsole() {
    if (console.everything === undefined) {
        console.everything = [];
        function TS() {
            return new Date().toLocaleString('sv', { timeZone: 'UTC' }) + 'Z';
        }
        window.onerror = function (error, url, line) {
            console.everything.push({
                type: 'exception',
                timeStamp: TS(),
                value: { error, url, line },
            });
            return false;
        };
        window.onunhandledrejection = function (e) {
            console.everything.push({
                type: 'promiseRejection',
                timeStamp: TS(),
                value: e.reason,
            });
        };

        function hookLogType(logType) {
            const original = console[logType].bind(console);
            return function () {
                if (logType === 'error') {
                    errorData.push({
                        // type: logType,
                        timeStamp: TS(),
                        value: Array.from(arguments),
                        // value: arguments
                    });
                    errorObj.data = errorData;
                }
                if (logType === 'warn') {
                    warnData.push({
                        // type: logType,
                        timeStamp: TS(),
                        value: Array.from(arguments),
                        // value: arguments[0]
                    });
                    warnObj.data = warnData;
                }
                original.apply(console, arguments);
            };
        }

        // ['log', 'error', 'warn', 'debug'].forEach(logType=>{
        ['error', 'warn'].forEach((logType) => {
            console[logType] = hookLogType(logType);
        });
    }
    bothData = {};
    bothData = {
        error: errorObj,
        warn: warnObj,
    };
}

let setTimeOut;

function downloadFile() {
    const element = document.createElement('a');
    element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' +
            encodeURIComponent(JSON.stringify(userRoutingTrack))
    );
    // element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(bothData)));
    element.setAttribute('download', 'test.json');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    clearDB();
}

function isUserLoggedIn() {
    // setTimeOut = setTimeout(()=>{
    //     downloadFile();
    //     isUserLoggedIn();
    // },900000)
}
const isUserLoggedOut = () => {
    clearTimeout(setTimeOut);
};
